import React from 'react';
import Spinner from '../spinner/spinner';
import './info.css';

function Info() {

    return (
        <div className="settings">
            <a href="/api/swagger-ui.html">Open API</a>
        </div>
    );
};

export default Info;
import React from 'react';
import Spinner from '../spinner/spinner';
import './settings.css';

function Settings() {

    return (
        <div className="settings">
            <h1>Coming soon...</h1>
            <Spinner />
        </div>
    );
};

export default Settings;